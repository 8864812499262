import Modal from "./Modal";
import ConfirmationModal from "./ConfirmationModal";
import NoRecorder from "./NoRecorder";
import Hint from "./Hint";
import { addResourcesBundle } from "../../i18n";
import en from "./en.json";

addResourcesBundle({ en });

export { Modal, ConfirmationModal, NoRecorder, Hint };
